var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('pageTitle',{attrs:{"txt":"追加発注 一覧","backBtn":_vm.backBtn,"backURL":"/iseya/home/"}}),_c('div',{attrs:{"id":"wrapper"}},[_c('ul',{staticClass:"list"},_vm._l((_vm.stores),function(store){return _c('li',{key:store.id,staticClass:"list-item"},[_c('router-link',{attrs:{"to":{
            path: '/iseya/order/extraDetail/',
            query: {
              date: _vm.ymdFormat(_vm.date),
              shopID: store.id,
              shopName: store.name,
              tel: store.tel
            }
          }}},[_c('orderItem',{attrs:{"id":store.id,"date":_vm.ymdFormat(_vm.date),"name":store.name,"tel":store.tel,"period":_vm.startDate + '～' + _vm.endDate,"unread":store.is_read,"url":"/iseya/order/extraDetail/"}})],1)],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }